.banner-img{
    text-align: right;
    img{
        height: 350px;
        object-fit: contain;
        margin-left: auto;
    }
    .research-img{
        height: 260px;
    }
}
.about-banner{
    padding: 130px 0px 0px;
}
.choose-content{
    li{
        margin-top: 20px;
    }
}

@media(min-width: 992px){
    .choose-content{
        li{
            width: 50%;
            padding-right: 50px;
        }
    }
    .banner-content{
        .btm-border{
            &-line{
                &::after, &::before{
                  right: auto;
                }
                &::before{
                  animation: move_circle-left 7s infinite alternate ease-in-out;
                }
              }
        }
    }
 
}