.contact-content{
    background-color: #daeaff4a;
    padding: clamp(25px, 5vw, 45px);
    
}
.contact-content{
    h5{
        font-size: 1.1rem;
    }
}
.form-group {
    margin-top: clamp(30px ,4vw, 40px);
}
.contact-banner{
    padding: 130px 0px 50px;
}
@media(max-width: 991px){
    .contact-banner{
        padding: 100px 0px 0px;
    }
}