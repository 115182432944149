.slide{
    .carousel-indicators{
        bottom: -80px;
         [data-bs-target]{
                width: 14px;
                height: 14px;
                background: #00d4ff;
                border-radius: 100%;
                min-width: 10px;
                min-height: 10px;   
                border: 0;
                &.active{
                    background: #090979;
                }
        }
    } 
}

@media(min-width: 768px){
    .listing{
        max-width: 350px;
      }
}
@media(max-width: 991px){
    .navbar-brand{
        img{
            height: 50px;
        }
    }
    .team-heading{
        max-width: 700px;
    }
    .our-team{
        .stratagic-img{
            position: absolute;
            right: 0;
            max-width: 400px;
            bottom: 50px;
        }
       
    }
   
}
