$primary: #0d5ee0;
$light-blue: #ddeaff;
$dark-blue: rgba(9,9,121,1);
$light-gray: #f0f3f7;
$menu-color: #073797;
$gray: #959aa0;
$black: #101519;
$white: #fff;
$light-white: #ffffff9c;
$paragraph: #494a54;
$sec-bg: #daeaff4a;
$gradient: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);